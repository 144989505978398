export default {
	activeMeetingGroup: "Active meeting group",
	titles: {
		displayOptions: "Public site display options",
		socialMedia: "Your organization’s social media ",
		calenderSettings: "ICS Calendar settings",
		display: "Display options",
	},
	checkbox: {
		attendanceDisplay: "Display attendance and recorded voting records",
		linkLogoInPortal: "Link the organization logo to the organization website",
		meetingsDisplay: "Show meetings without published documents",
		public: "Make portal public",
		showLogoInPortal: "Show the organization logo in the portal",
		videoTitle: "Display the video tile",
	},
	url: {
		fbUrl: "Facebook URL",
		fbUrlLink: "Facebook URL link",
		linkUrl: "LinkedIn URL",
		linkUrlLink: "LinkedIn URL link",
		twitUrl: "X / Twitter URL",
		twitUrlLink: "X / Twitter URL link",
		utubeUrl: "YouTube URL",
		utubeUrlLink: "YouTube URL link",
		instaUrl: "Instagram URL",
		instaUrlLink: "Instagram URL link",
		email: "Email",
	},
	ICSSettings: {
		icsMeetings: "Public meetings ICS feed",
		url: "URL",
		name: "Name",
		subtitle: "Add external calendars to public site",
	},
	snackbarMessage: {
		backgroundImageAdded: "Backgroung Image added",
		bgImg: "Background image added",
		calanderAdded: "External Calander Added",
		calanderDeleted: "External Calander Data Deleted",
		errorUrl: "Invalid ICS feed url",
		icsCloudflareError: "Cloudflare error when fetching ICS feed",
		imageDeleted: "Image Deleted",
		imageUploadError: "The portal background image requires a minimum width of 1920px and a minimum height of 1080px",
		imgDelete: "{{image name}} deleted",
		invalidICSURL: "Invalid external calendar URL",
		logo: "Organization logo added",
		logoAdded: "Organization logo added ",
		success: "Settings saved successfully",
	},
	errorMessage: {
		facebook: "Facebook URL is invalid",
		linkedin: "LinkedIn URL is invalid",
		twitter: "X / Twitter URL is invalid",
		youtube: "Youtube URL is invalid",
		instagram: "Instagram URL is invalid",
		email: "Email address is invalid",
		ICSUrlExist: "ICS feed URL already exist",
		ICSURLInvalid: "Invalid ICS feed URL",
		contrastRatio: "Invalid Contrast Ratio",
		backgroundImage: "Minimum image dimensions: 1920x1080 (Width: 1920px, Height: 1080px)",
		backgroundImageFileType: "Supported file types: png, jpg, jpeg",
		url: "Url is required",
		name: "Name is required",
	},
	popup: {
		feed: "ICS feed help",
		subtitle: "This will add calendar events to your external calendar of choice.",
		step1: "1. Copy this url",
		step2: "2. Add to external calendar",
	},
	dialog: {
		calender: "ICS Calendar help",
		subtitle:
			"External calendars can be added to allow events from calendars outside of your Community site to display publicly alongside your Community meeting events.To add an external calendar:",
		step1: " 1. Copy the URL of the calendar ICS file.",
		step2: "2. Add to the URL box below.",
		step3: " 3. Give the calendar a name. (This name is displayed publicly on the Portal calendar in the filter drop down menu)",
		step4: "4. Click Add.",
		step5: "5. Click Save.",
	},
	upload: {
		portalColor: "Button color:",
		portalBackgroundImage: "Background image",
		agendaLogo: "Agenda organization logo",
		uploadImage: "Upload Background Image",
		uploadLogo: "Upload Organization Logo",
	},
	boardDocs: {
		importProgressDialog: {
			title: "Importing from BoardDocs",
		},
		labels: {
			companyCode: "Company code",
			continueImport: "Continue the import from where it last left off",
			downloadLog: "Download log",
			excludePolicies: "Exclude policies from import",
			import: "BoardDocs import",
			importAllCommittees: "Import all committees",
			importData: "Import Data",
			importOnlyPolicies: "Import only policies",
			importWelcomePage: "Import the welcome/featured page",
			logs: "BoardDocs import logs",
			onlyImportNewUsers: "Only import new users",
			password: "Password",
			userName: "User name",
		},
		snackbar: {
			importActive: "Import already in progress",
			invalidCredentials: "Invalid credentials",
			success: "Import succeeded",
		},
		tooltips: {
			downloadLog: "Download the selected BoardDocs import log",
			importData: "Import the BoardDocs data",
		},
		validation: {
			boardsCompanyCode: "Unable to load the BoardDocs committees without a company code",
			boardsCredentials: "Unable to load the BoardDocs committees due to invalid credentials",
			companyCode: "Company code required",
			password: "Password required",
			userName: "User name required",
		},
	},
	boardDocsImport: "Enable BoardDocs import",
	boardPaq: {
		buttons: {
			clearDocuments: "Clear documents",
			downloadLog: "Download log",
			importData: "Import data",
			uploadDocuments: "Upload documents",
			uploadExcel: "Upload excel",
		},
		importProgressDialog: {
			title: "Importing from BoardPaq",
		},
		labels: {
			continueImport: "Continue the import from where it last left off",
			documentsUploaded: "Documents uploaded: {{count}}",
			excelLastUploaded: "Excel file last uploaded: {{date}}",
			excelNotUploaded: "Excel file not uploaded",
			import: "BoardPaq import",
			instructions:
				"Upload the BoardPaq Excel file and the documents in the ZIP file. You will need to extract the documents from the ZIP file first to a separate folder, then select the folder to upload.",
			logs: "BoardPaq import logs",
		},
		snackbar: {
			documentClearFailure: "Failed to clear the previously uploaded documents",
			documentClearSuccess: "Cleared the uploaded documents",
			documentUploadFailure: "Failed to upload the document '{{path}}'",
			excelUploadFailure: "Failed to upload Excel file",
			excelUploadSuccess: "Excel file uploaded",
			importActive: "Import already in progress",
			success: "Import succeeded",
		},
		tooltips: {
			clearDocuments: "Clear the previously uploaded documents",
			downloadLog: "Download the selected BoardPaq import log",
			importData: "Import the BoardPaq data",
			uploadDocuments: "Upload the documents from the BoardPaq ZIP file",
			uploadExcel: "Upload the Excel file containing the BoardPaq data",
		},
	},
	boardPaqImport: "Enable BoardPaq import",
	buttons: {
		deleteBackgroundImage: "Delete background image",
		saveSettings: "Save",
		uploadFolder: "Upload folder",
		add: "Add",
		copy: "Copy",
	},
	codeOfConduct: "Code of conduct",
	codeOfConductSummary: "Code of conduct summary",
	communityLite: "Enable Community Essentials",
	dataExport: {
		buttons: {
			exportData: "Export data",
		},
		exportProgressDialog: {
			title: "Exporting data from Community",
		},
		labels: {
			download: "Download the exported data",
			title: "Community data export",
		},
		snackbar: {
			exportActive: "Data export already in progress",
			success: "Data export succeeded",
			unableToExport: "Unable to export data",
		},
		tooltips: {
			exportData: "Export data from Community",
		},
	},
	policyGovernance: "Enable Community Policy",
	communityMeetings: "Enable Community Meetings",
	contrast: {
		aa: "Passes Web Content Accessibility Guidelines (WCAG) AA level for any size text and AAA for large text (above 18pt or bold above 14pt)",
		aaa: "Passes Web Content Accessibility Guidelines (WCAG) AAA level for any size text",
		fail: "Fails Web Content Accessibility Guidelines (WCAG)",
	},
	email: {
		body: "Email body text",
		rtsApproved: "Email template: Request to speak approved",
		rtsCanceled: "Email template: Request to speak canceled",
		rtsRejected: "Email template : Request to speak rejected",
		rtsRequestChange: "Email template: Request to speak adjustment required",
		subjectLine: "Email subject line text",
	},
	folderUpload: "Upload a folder",
	numberOfMeetingGroups: "Number of meeting groups allowed",
	policyFolderUpload: {
		buttons: {
			upload: "Upload policy folder",
		},
		labels: {
			book: "Book",
			title: "Upload a policy folder",
		},
		options: {
			draft: "Upload as draft",
			published: "Upload as published",
		},
		tooltips: {
			upload: "Upload an entire folder of policies",
		},
	},
	requestToSpeak: "Request to speak",
	tabs: {
		features: "Features",
		folderUpload: "Folder upload",
		portalStyles: "Public site",
		publicRequestToSpeak: "Public request to speak",
		voting: "Voting",
	},
	title: "Settings",
	tooltips: {
		boardDocsImport: "Toggle whether or not the BoardDocs import is enabled",
		boardPaqImport: "Toggle whether or not the BoardPaq import is enabled",
		communityLite: "Toggle whether or not Community Lite is enabled",
		policyGovernance: "Toggle whether or not Policy Governance is enabled",
		communityMeetings: "Toggle whether or not Community Meetings is enabled",
		deleteBackgroundImage: "Delete the public site background image",
		folderUpload: "Upload an entire folder",
		add: "Add external calenders",
		requestToSpeakToggle: "Enable request to speak settings for the public",
		saveSettings: "Save settings",
		updateAgendaLogoImage: "Update the agenda logo",
		updateBackgroundImage: "Update the public site background image",
		copy: "Copy ICS Feed",
		remove: "Remove",
		viewOldBackEnd: "Temporarily view Meeting Manager and Document Center",
	},
	validation: {
		invalidHexColor: "Valid hex color required (#000000)",
		invalidRgbColor: "Valid rgb color required (rgb(0,0,0))",
	},
	viewOldBackEnd: "Temporarily view MM && DC",
	voting: {
		absent: "Absent (during voting)",
		abstain: "Abstain",
		against: "Noes/against/?",
		carried: "Disposition carries",
		digitalVoting: "Enable digital voting",
		failed: "Disposition fails",
		for: "Ayes/for/?",
		voting: "Voting",
		votingCustomization: "Voting customization",
		votingVerbiage: "Voting verbiage",
		enableMemberDigitalVoting: "Enable member digital voting",
		abstainCountAs: {
			label: "Abstain count as",
			for: "For",
			against: "Against",
			none: "None",
		},
	},
	ICStable: {
		actions: "Actions",
		name: "Name",
		url: "URL",
	},
	ICSNoDataLable: "No Added external calendars to public site",
	help: "Help",
	reloadText: "If the color does not properly load after saving reload the page by pressing Ctrl+F5",
	labels: {
		documentCenter: "Document Center",
		meetingManager: "Meeting Manager",
		portalHexColor: "Portal hex color",
		portalRgbColor: "Portal RGB color",
	},
};
