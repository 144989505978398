import React, { useState } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { ListItem, ListItemText, Typography } from "@mui/material";

import tableOfContentsStyle from "assets/jss/components/tableOfContentsStyle";
import telemetryAddEvent from "utils/telemetryAddEvent";
import TOCAttachments from "./TOCAttachments";
import TOCPrimaryText from "./TOCPrimaryText";
import useAttachmentsHighlight from "../PdfViewerNew/utils/useAttachmentsHighlight";
import { useDroppable } from "@dnd-kit/core";
import Draggable from "atlas/components/DragAndDrop/Draggable";
import DragHandle from "atlas/components/DragAndDrop/DragHandle";
import MoveButtons from "./CommonComponents/MoveButtons";
import { Presentation } from "../Icons";
import { handleKeyDownDND, handleListItemKeyDown, stripTags } from "./CommonComponents/tocUtils";
import TocContentWrapper from "./CommonComponents/TocContentWrapper";

const useTableOfContentsStyles = makeStyles(tableOfContentsStyle);

const TOCMeetingItem = (props) => {
	const {
		item: { fields },
		item,
		isMemberOnlySection,
		isConsentSection,
		isPublicCommentSection,
		toggleTableOfContent = () => {},
		isSmallDevice,
		parserOptions,
		isClosedMeeting,
		idPrefix = "outline",
		button,
		selected,
		setActive,
		telemetryPage,
		setDataForAttachmentDocument,
		setDataForOutlineItem,
		isPdfSidebar,
		selectedId,
		openGoalsPopup,
		canDrag = false,
		canDrop = false,
		isLastChildItem = false,
		isActive = false,
		moveItem = () => {},
		renderControl = {},
		index,
		getNextAvailablePosition = () => {},
		isAgenda = false,
		isMinutes = false,
		presenting = false,
		clone = false,
		getOverallTabIndex = () => {},
	} = props;
	const dispatch = useDispatch();
	const tableOfContentsClasses = useTableOfContentsStyles({
		lineLimit: renderControl?.lineLimit ? renderControl?.lineLimit : fields?.Name?.Value?.length > 50 ? 2 : 1,
	});
	const [clickedOnAttachment, setClickedOnAttachment, clickedOnAttachmentInSidePanel] = useAttachmentsHighlight(false);
	const [hoverStatus, setHoverStatus] = useState(false);
	const [arrowNavigation, setArrowNavigation] = useState(true);

	const dragProps = canDrag ? { dragId: item.guid, dragComponent: "div", tabIndex: null } : {};

	const droppable = useDroppable({ id: item.guid });
	const { setNodeRef } = canDrop ? droppable : {};

	const handleBlur = () => {
		if (arrowNavigation) {
			setHoverStatus(false);
		}
	};

	const handleMove = (item, isMoveUp) => {
		setHoverStatus(false);

		if (isAgenda) {
			telemetryAddEvent("Agenda builder reorder - outline button");
		} else {
			telemetryAddEvent("Minutes builder reorder - outline button");
		}

		moveItem(item, isMoveUp);
	};

	const ConditionForNonOutline = !canDrag && !(isAgenda || isMinutes);
	const ConditionForOutline = canDrag || isAgenda || isMinutes;
	const showAttachments = clone ? false : true;

	const getBulletAndText = () => (
		<>
			<Typography variant="h5" component="span" className={clsx("outline-number", tableOfContentsClasses.bullet)}>
				{fields.Number.Value}
			</Typography>
			<ListItemText
				className={clsx({
					[tableOfContentsClasses.limitLines]: Object.keys(renderControl).length <= 0,
					[tableOfContentsClasses.primaryTextEllipses]: Object.keys(renderControl).length > 0,
				})}
				primaryTypographyProps={{ variant: "h5" }}
				primary={
					<TOCPrimaryText
						text={`<p>${stripTags(fields.Name.Value, ["a"])}</p>`}
						parserOptions={parserOptions}
						showTruncationTooltip
						truncationThreshold={isPdfSidebar ? (!renderControl ? 23 : 84) : 50}
						isPdfSidebar={isPdfSidebar}
						renderControl={Object.keys(renderControl).length > 0}
					></TOCPrimaryText>
				}
			/>
		</>
	);

	return (
		!item.deleted && (
			<TocContentWrapper
				condition={isAgenda || isMinutes}
				wrapper="span"
				wrapperProps={{
					id: `${item.guid}-toc-meeting-item`,
					ref: setNodeRef,
					onKeyDown: (e) => handleListItemKeyDown(e, setArrowNavigation),
				}}
			>
				<ListItem
					id={`${idPrefix}-${item.guid}`}
					{...(renderControl && item.isFirstElementForSkipContent && { id: "cont" })}
					data-cy={`${idPrefix}-${item.guid}`}
					className={clsx(tableOfContentsClasses.listItem, {
						[tableOfContentsClasses.closedChanged]: item.original && item.original.closed && !item.fields.Closed.Value,
					})}
					button={button}
					role=""
					component={"li"}
					disableGutters
					divider
					classes={{
						root: clsx(
							ConditionForOutline ? "candrag-outline-item-secondary" : "outline-item-secondary",
							ConditionForNonOutline && tableOfContentsClasses.tabFocusStyling,
							ConditionForNonOutline && item.copied && renderControl?.isAnimationAdded(document.getElementById(`${idPrefix}-${item.guid}`))
								? "transition-animation"
								: item.copied && renderControl
									? "transition-animation-added"
									: "",
							{
								"toc-padding": hoverStatus && canDrag,
								"toc-active-item": isActive && ConditionForOutline,
								"member-only-section": !canDrag && isMemberOnlySection,
								"canDrag-member-only-section": canDrag && isMemberOnlySection,
								"consent-section-outline": isConsentSection,
								"public-comment-section-outline-pdfsidebar": isPublicCommentSection && isPdfSidebar,
								"public-comment-section-outline": isPublicCommentSection && !isPdfSidebar,
								"closed-meeting": isClosedMeeting,
								"member-only-section-last-child-item":
									isLastChildItem && item.attachments.length === 0 && isMemberOnlySection && ConditionForOutline,
								"consent-section-last-child-item":
									isLastChildItem && item.attachments.length === 0 && isConsentSection && ConditionForOutline,
								"public-comment-section-last-child-item":
									isLastChildItem && item.attachments.length === 0 && isPublicCommentSection && ConditionForOutline,
								pdfSideBar: isPdfSidebar,
								hyperlinkAttachment: isPdfSidebar && selectedId && selectedId.includes(item.guid) && !clickedOnAttachment,
								[tableOfContentsClasses.backgroundColorForSelected]:
									ConditionForNonOutline && renderControl && renderControl.highlightCheck && renderControl.highlightCheck(item),
								[tableOfContentsClasses.itemValueAndTextGapping]: ConditionForNonOutline && renderControl,
							},
						),
					}}
					onMouseOver={() => setHoverStatus(true)}
					onMouseOut={() => setHoverStatus(false)}
					onFocus={() => setHoverStatus(true)}
					onBlur={handleBlur}
					onClick={
						button && isPdfSidebar
							? (e) => {
									setDataForOutlineItem(item);
									setClickedOnAttachment(false);
									dispatch(setActive(item.guid, true));
								}
							: button
								? () => {
										telemetryAddEvent(`${telemetryPage} - Outline navigation`);

										dispatch(setActive(item.guid, true));
										if (isSmallDevice) {
											toggleTableOfContent();
										}
									}
								: undefined
					}
					selected={selected && selected.includes(item.guid)}
					tabIndex={getOverallTabIndex(index)}
					onKeyDown={(e) => renderControl && renderControl?.keyDownAction?.(e)}
				>
					<>
						{canDrag ? (
							<Draggable className={tableOfContentsClasses.draggableListItem} {...dragProps} handleKeyDown={handleKeyDownDND}>
								<DragHandle role="button" />
							</Draggable>
						) : null}
						{getBulletAndText()}
						{canDrag && (
							<MoveButtons
								moveItem={handleMove}
								item={item}
								showMoveUp={getNextAvailablePosition(item, true).nextPosition !== null}
								showMoveDown={getNextAvailablePosition(item, false).nextPosition !== null}
							/>
						)}
						{presenting && !hoverStatus && selected && selected.includes(item.guid) && <Presentation />}
					</>
					{renderControl?.isBoardNotesPresent && renderControl.isBoardNotesPresent(item)}
					{renderControl?.isCheckBoxVisible && renderControl.isCheckBoxVisible(item) && renderControl.rendercomponent(item, index)}
				</ListItem>

				{showAttachments && (
					<TOCAttachments
						attachments={item.attachments}
						item={item}
						itemGuid={item.guid}
						isMemberOnlySection={isMemberOnlySection}
						isConsentSection={isConsentSection}
						isPublicCommentSection={isPublicCommentSection}
						toggleTableOfContent={toggleTableOfContent}
						telemetryPage={telemetryPage}
						setDataForAttachmentDocument={setDataForAttachmentDocument}
						isPdfSidebar={isPdfSidebar}
						setActive={setActive}
						clickedOnAttachmentInSidePanel={clickedOnAttachmentInSidePanel}
						isAgendaItem
						selectedId={selectedId}
						openGoalsPopup={openGoalsPopup}
						isLastChildItem={isLastChildItem}
						canDrag={ConditionForOutline}
						renderControl={renderControl}
					/>
				)}
			</TocContentWrapper>
		)
	);
};

export default React.memo(TOCMeetingItem);
