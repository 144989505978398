import React from "react";
import { useTranslation } from "react-i18next";
import { useState, useContext, useEffect } from "react";

import makeStyles from "@mui/styles/makeStyles";
import { Box, MenuItem } from "@mui/material";

import settingsStyle from "assets/jss/components/settingsStyle";
import InputLabel from "atlas/components/FormControls/InputLabel";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import SelectInput from "atlas/components/FormControls/SelectInput";
import StyledSwitch from "atlas/components/FormControls/StyledSwitch";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import ToggleOldBackend from "./ToggleOldBackend";
import BoardDocsImport from "./BoardDocsImport";
import BoardPaqImport from "./BoardPaqImport";
import DataExport from "./DataExport";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import Icon from "atlas/components/Icon/Icon";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import telemetryAddEvent from "utils/telemetryAddEvent";

const useStyles = makeStyles(settingsStyle);

const DEFAULT_FULL_MEETING_GROUPS = 2;

const Features = (props) => {
	const { settings, updateSettingsValue } = props;

	const { lite, communityMeetings } = useContext(SettingsContext);
	const [showButtons, setShowButtons] = useState(false);

	const [buttonStyle, setButtonStyle] = useState({
		liteEnabled: lite.enabled,
		fullCommunityVariant: !lite.enabled ? "contained" : "outlined",
		communityEssentialVariant: lite.enabled ? "contained" : "outlined",
	});

	const { t } = useTranslation("settings");
	const classes = useStyles();
	useEffect(() => {
		if (communityMeetings) {
			setShowButtons(true);
		} else {
			setShowButtons(false);
		}
	}, [communityMeetings]);

	const toggleCommunityMeetingsButton = (checked) => {
		handleChange({ target: { checked } }, "communityMeetings", true);
		setShowButtons((prev) => !prev);
	};

	const onCommunityLiteChange = (button, e) => {
		if (button === "fullCommunity") {
			setButtonStyle({
				liteEnabled: false,
				fullCommunityVariant: "contained",
				communityEssentialVariant: "outlined",
			});
			handleChange({ target: { checked: false } }, "lite", true);
		} else if (button === "communityEssentials") {
			setButtonStyle({
				liteEnabled: true,
				fullCommunityVariant: "outlined",
				communityEssentialVariant: "contained",
			});
			handleChange({ target: { checked: true } }, "lite", true);
		}
	};

	const handleChange = (e, setting, checkbox, isNumeric, autoSave = false, preventReload = false) => {
		const {
			target: { value, checked },
		} = e;

		if (settings[setting] === value) {
			return; // don't re-save unchanged content;
		}

		let processedValue = checkbox ? checked : isNumeric ? parseInt(value, 10) : value;
		if ((setting === "boards" && isNaN(processedValue)) || processedValue < 0) {
			processedValue = 0;
		}

		updateSettingsValue(setting, processedValue, undefined, autoSave, preventReload);
	};

	return (
		<>
			{settings ? (
				<Box>
					{typeof settings.communityMeetings !== "undefined" && (
						<div className={classes.labelAndSwitch}>
							<div className={classes.sectionLabel}>
								<InputLabel htmlFor="community-meetings" label={t("communityMeetings")} size="large" />
							</div>
							<div className={classes.labelWithOtherContent}>
								<StyledSwitch
									id="community-meetings"
									classes={{
										label: classes.switchLabel,
										stateLabel: classes.switchInstructions,
									}}
									inline
									title={t("tooltips.communityMeetings")}
									size="small"
									objectToUpdate={settings}
									fieldToUpdate="communityMeetings"
									onChange={(checked) => {
										telemetryAddEvent("Settings - Community Meetings");
										toggleCommunityMeetingsButton(checked);
										if (!checked) {
											handleChange({ target: { value: DEFAULT_FULL_MEETING_GROUPS } }, "boards", false, true);
										}
									}}
									data-cy="policy-governance-enabled"
								/>
							</div>
						</div>
					)}
					{showButtons && (
						<div className={classes.communityButtons}>
							<div>
								<ButtonWithTooltip
									primary
									onClick={(e) => {
										onCommunityLiteChange("fullCommunity", e);
									}}
									title={""}
									variant={buttonStyle.fullCommunityVariant}
									className={classes.communityButton}
								>
									<div className={classes.activeButton}>
										{!buttonStyle?.liteEnabled && (
											<span style={{ paddingTop: "5px" }}>
												<Icon name={"check-mark-with-circle"} />
											</span>
										)}
										<div>{"Full Community"}</div>
									</div>
								</ButtonWithTooltip>
							</div>
							<div>
								<ButtonWithTooltip
									primary
									onClick={() => {
										onCommunityLiteChange("communityEssentials");
									}}
									title={""}
									variant={buttonStyle.communityEssentialVariant}
									className={classes.communityButton}
								>
									<div className={classes.activeButton}>
										{buttonStyle?.liteEnabled && (
											<span style={{ paddingTop: "5px" }}>
												<Icon name={"check-mark-with-circle"} />
											</span>
										)}
										<div> {"Community Essential"}</div>
									</div>
								</ButtonWithTooltip>
							</div>
						</div>
					)}
					{typeof settings.policy !== "undefined" && (
						<div className={classes.labelAndSwitchForPolicy}>
							<div className={classes.sectionLabel}>
								<InputLabel htmlFor="policy-governance-enabled" label={t("policyGovernance")} size="large" />
							</div>
							<div className={classes.labelWithOtherContent}>
								<StyledSwitch
									id="policy-governance-enabled"
									classes={{
										label: classes.switchLabel,
										stateLabel: classes.switchInstructions,
									}}
									inline
									title={t("tooltips.policyGovernance")}
									size="small"
									objectToUpdate={settings}
									fieldToUpdate="policy"
									onChange={(checked) => {
										handleChange({ target: { checked } }, "policy", true);
									}}
									data-cy="policy-governance-enabled"
								/>
							</div>
						</div>
					)}

					{!settings.lite && (
						<div>
							<OutlinedInput
								id="boards-allowed"
								noDefaultClassName
								size="small"
								label={t("numberOfMeetingGroups")}
								externalLabel
								labelSize="large"
								value={settings.boards > 0 ? settings.boards : ""}
								placeholder={t("app:options.unlimited")}
								onChange={(e) => {
									handleChange(e, "boards", false, true);
								}}
								data-cy="boards-allowed"
							/>
						</div>
					)}
					{settings.lite && (
						<div>
							<SelectInput
								id="boards"
								className={classes.selectInput}
								noDefaultClassName
								fullWidth
								size="small"
								label={t("activeMeetingGroup")}
								externalLabel
								labelSize="large"
								value={settings.activeBoard}
								onChange={(e) => {
									handleChange(e, "activeBoard", false, true);
								}}
								data-cy="active-board"
							>
								{settings.availableBoards.map((board) => (
									<MenuItem key={board.id} value={board.id} data-cy={`boards-${board.id}`}>
										<div className={classes.menuItemText}>{board.name}</div>
									</MenuItem>
								))}
							</SelectInput>
						</div>
					)}
					{typeof settings.viewOldBackEnd !== "undefined" ? <ToggleOldBackend settings={settings} handleChange={handleChange} /> : null}
					{typeof settings.boardDocsImport !== "undefined" && (
						<>
							<div className={classes.sectionLabel}>
								<InputLabel htmlFor="boarddocs-import-enabled" label={t("boardDocsImport")} size="large" />
							</div>
							<div className={classes.labelWithOtherContent}>
								<StyledSwitch
									id="boarddocs-import-enabled"
									classes={{
										label: classes.switchLabel,
										stateLabel: classes.switchInstructions,
									}}
									inline
									title={t("tooltips.boardDocsImport")}
									size="small"
									objectToUpdate={settings}
									fieldToUpdate="boardDocsImport"
									onChange={(checked) => {
										handleChange({ target: { checked } }, "boardDocsImport", true, false, true);
									}}
									data-cy="boarddocs-import-enabled"
								/>
							</div>
						</>
					)}
					{settings.boardDocsImport && (
						<BoardDocsImport
							companyCode={settings.boardDocsCompanyCode}
							progressGuid={settings.boardDocsProgressGuid}
							handleChange={handleChange}
						/>
					)}
					{typeof settings.boardPaqImport !== "undefined" && (
						<>
							<div className={classes.sectionLabel}>
								<InputLabel htmlFor="boardpaq-import-enabled" label={t("boardPaqImport")} size="large" />
							</div>
							<div className={classes.labelWithOtherContent}>
								<StyledSwitch
									id="boardpaq-import-enabled"
									classes={{
										label: classes.switchLabel,
										stateLabel: classes.switchInstructions,
									}}
									inline
									title={t("tooltips.boardPaqImport")}
									size="small"
									objectToUpdate={settings}
									fieldToUpdate="boardPaqImport"
									onChange={(checked) => {
										handleChange({ target: { checked } }, "boardPaqImport", true, false, true);
									}}
									data-cy="boardpaq-import-enabled"
								/>
							</div>
						</>
					)}
					{settings.boardPaqImport && (
						<BoardPaqImport
							uploadedFiles={settings.boardPaqFiles || {}}
							progressGuid={settings.boardPaqProgressGuid}
							handleChange={handleChange}
						/>
					)}
					<DataExport />
				</Box>
			) : (
				<CircularProgressIndicator />
			)}
		</>
	);
};

export default Features;
